.m-t-5 {
  margin-top: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-l-5 {
  padding-left: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-r-5 {
  margin-right: 5px !important;
}

.m-5 {
  margin: 5px !important;
}

.p-r-5 {
  padding-right: 5px !important;
}

.p-5 {
  padding: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-l-10 {
  padding-left: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-r-10 {
  margin-right: 10px !important;
}

.m-10 {
  margin: 10px !important;
}

.p-r-10 {
  padding-right: 10px !important;
}

.p-10 {
  padding: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-l-15 {
  padding-left: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-r-15 {
  margin-right: 15px !important;
}

.m-15 {
  margin: 15px !important;
}

.p-r-15 {
  padding-right: 15px !important;
}

.p-15 {
  padding: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-r-20 {
  margin-right: 20px !important;
}

.m-20 {
  margin: 20px !important;
}

.p-r-20 {
  padding-right: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-l-25 {
  padding-left: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-r-25 {
  margin-right: 25px !important;
}

.m-25 {
  margin: 25px !important;
}

.p-r-25 {
  padding-right: 25px !important;
}

.p-25 {
  padding: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-l-30 {
  padding-left: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-r-30 {
  margin-right: 30px !important;
}

.m-30 {
  margin: 30px !important;
}

.p-r-30 {
  padding-right: 30px !important;
}

.p-30 {
  padding: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-l-35 {
  padding-left: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-r-35 {
  margin-right: 35px !important;
}

.m-35 {
  margin: 35px !important;
}

.p-r-35 {
  padding-right: 35px !important;
}

.p-35 {
  padding: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-l-40 {
  padding-left: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-r-40 {
  margin-right: 40px !important;
}

.m-40 {
  margin: 40px !important;
}

.p-r-40 {
  padding-right: 40px !important;
}

.p-40 {
  padding: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-l-45 {
  padding-left: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-r-45 {
  margin-right: 45px !important;
}

.m-45 {
  margin: 45px !important;
}

.p-r-45 {
  padding-right: 45px !important;
}

.p-45 {
  padding: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-l-50 {
  padding-left: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

.m-r-50 {
  margin-right: 50px !important;
}

.m-50 {
  margin: 50px !important;
}

.p-r-50 {
  padding-right: 50px !important;
}

.p-50 {
  padding: 50px !important;
}

:root {
  font-size: 15px;
}

body {
  background: #12161c;
}

.homeMarqueePre {
  color: #fff;
  padding: 5px 5px;
  background: #000000;
  font-size: 13px;
  font-weight: 600;
}

.viewTableHeightBox {
  height: calc(100% - 100px) !important;
}

.homeMarquee {
  width: 100%; /* Make sure the container takes the full width */
  overflow: hidden;
}

.marqueeContentWrapper {
  width: 100%;
  display: inline-block; /* Allow the content to expand horizontally */
}

.marqueeContent {
  white-space: nowrap;
  width: 100%;
  animation: marqueeAnim 36s linear infinite; /* Adjust duration as needed */
}

.marqueeContent.paused {
  animation-play-state: paused;
}

@keyframes marqueeAnim {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.homeMarquee {
  color: #fff;
  padding: 10px 5px;
  background: #000000;
  font-size: 13px;
  font-weight: 600;
  z-index: 9999 .homeMarquee strong;
  z-index-font-size: 15px;
}
.homeMarquee span {
  display: inline-block;
  padding: 0 20px;
  border-right: 1px solid #fff;
}
.homeMarquee span b {
  color: #6bfd00;
}
.homeMarquee span:nth-last-child(1) {
  border-right: none;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.d-flex.justify-center {
  justify-content: center;
}
.d-flex.justify-flex-start {
  justify-content: flex-start;
}
.d-flex.align-flex-start {
  align-items: flex-start;
}
@media only screen and (max-width: 767px) {
  .d-flex:not(.no-responsive) {
    display: block !important;
  }
}

button {
  background: #dbdb6e !important;
}

.usfull-lins a {
  margin-right: 15px;
  margin-bottom: 10px;
}

.static-info {
  box-sizing: border-box;
  color: #fff;
}
.static-info .conatct {
  font-size: 20px;
}
.static-info .conatct a {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
}
.static-info .ads {
  flex-wrap: wrap;
}
.static-info .ads .info-box {
  width: 25%;
  border-style: solid;
  border-width: 6px;
  border-left-color: #dfdfdf;
  border-top-color: #dfdfdf;
  border-right-color: #12161d;
  border-bottom-color: #12161d;
  box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.35), inset 1px 1px 0px 1px #fefefe, inset -1px -1px 0 1px #848584;
}
.static-info .ads .info-box .image {
  height: 100px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.static-info .ads .info-box .image img {
  max-width: 100%;
  max-height: 100px;
}
.static-info .ads .info-box .image::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(18, 22, 28, 0.9);
  display: none;
  content: "Read more";
  top: 0;
  left: 0;
}
.static-info .ads .info-box .image:hover::after {
  display: flex;
  justify-content: center;
  align-items: center;
}
.static-info .ads .info-box.small {
  width: 10%;
}
.static-info .ads .info-box.small .image {
  height: 80px;
}
.static-info .ads .info-box.small .image img {
  max-width: 100%;
  max-height: 80px;
}
.static-info a {
  color: #f0b90b;
}

.bottom-right-info {
  position: fixed;
  text-align: center;
  bottom: 80px;
  right: 50px;
  width: 400px;
  color: #fff;
}
.bottom-right-info a {
  color: #f0b90b;
  cursor: pointer;
}
.bottom-right-info a:hover {
  text-decoration: none;
}

.connect-button {
  position: fixed;
  top: 0px;
  right: 0;
  z-index: 9999;
}
.connect-button.connected {
  background: #dbdb6e;
}
.connect-button.connected .right-time {
  padding: 0 20px;
  background: inherit;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 42px;
  font-size: 20px;
}

.name-price-box {
  margin-right: 20px;
}

.inside-black {
  background: #dbdb6e !important;
  border-left-color: #8d8d1b !important;
  border-top-color: #8d8d1b !important;
  border-right-color: #f2f2ab !important;
  border-bottom-color: #f2f2ab !important;
  border-width: 5px !important;
}
.inside-black.name-price {
  font-size: 20px;
  padding: 8px 15px;
}

.window {
  position: absolute !important;
  top: 42px !important;
  left: 28.5156%;
  min-width: 550px;
  max-width: 550px;
  min-height: 100px;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column !important;
  background: #dbdb6e !important;
}
.window.router {
  top: 2%;
  left: 307.5px;
  min-width: 500px;
  max-width: 500px;
}
.window.desktop-adds {
  top: 200px;
  min-width: 80%;
  max-width: 80%;
  width: 80%;
  left: 10%;
}
.window.info {
  top: 10vh;
  min-width: 600px;
  max-width: 600px;
  width: 600px;
}
.window.info.infotext .window-content {
  background-color: #fff !important;
  max-height: 600px !important;
  overflow-x: auto;
}
.window.info.infotext .window-content p {
  padding-bottom: 20px;
}
.window.info.infotext .window-content a {
  text-decoration: underline;
}
.window.info.infotext .window-content a:hover {
  text-decoration: none;
}
.window.paint {
  top: 10vh;
  min-width: 900px;
  max-width: 900px;
  width: 850px;
  height: 640px;
  left: 20%;
}
.window.borebnb {
  top: 40px;
  min-width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  width: calc(100% - 20px);
  height: calc(100% - 90px);
  left: 10px;
}
.window.solitaire {
  top: 20vh;
  min-width: 670px;
  max-width: 670px;
  width: 670px;
  height: 512px;
  left: 20%;
}
.window.connectWallet {
  top: 25vh;
  min-width: 330px;
  max-width: 500px;
  width: 100%;
  height: 350px;
  left: 35%;
}
.window.minesweeper {
  top: 20vh;
  min-width: 520px;
  max-width: 520px;
  width: 520px;
  height: 390px;
  left: 20%;
}
.window .iframe {
  flex-grow: 1;
}
.window .divider hr {
  border-bottom: 1px solid #fefefe;
  border-top: 1px solid #848584;
}
.window button {
  background: #dbdb6e !important;
  white-space: pre;
}
.window .window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.window .window-header.justify-center {
  justify-content: center;
}
.window .window-header.justify-flex-start {
  justify-content: flex-start;
}
.window .window-header.align-flex-start {
  align-items: flex-start;
}
.window .window-header:active {
  cursor: grabbing;
}
.window .window-header[data-active=true] {
  background: #12161c !important;
}
.window .window-content {
  padding: 8px !important;
  box-sizing: border-box !important;
  display: flex !important;
  flex-direction: column !important;
  flex-grow: 1 !important;
  width: 100% !important;
  min-height: 100px !important;
  min-width: 100px !important;
  max-height: 100% !important;
}
.window .window-content.no-padding {
  padding: 0 !important;
}
.window .window-content fieldset {
  box-shadow: -1px -1px 0 1px #b5b55c, inset -1px -1px 0 1px #b5b55c !important;
}
.window .window-content legend {
  background: #dbdb6e !important;
}
.window .tab-button {
  width: 150px;
  background: #dbdb6e !important;
}
.window .tab-button:before {
  background: #dbdb6e !important;
}
.window .tab-body {
  overflow-y: auto !important;
  padding: 0.5em !important;
  background: #dbdb6e !important;
}
.window .tab-body .tab-content {
  overflow-y: auto !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.5em !important;
  box-sizing: border-box !important;
  height: 100% !important;
}
.window .tab-body .tab-content.no-padding {
  padding: 0 !important;
}
.window .graph-tab {
  width: 100%;
  height: 100%;
}
.window .graph-tab .tab-box {
  width: calc(100% - 140px);
  height: calc(100% - 34px);
}
@media only screen and (max-width: 430px) {
  .window .graph-tab .tab-box {
    width: 100%;
    height: calc(100% - 34px);
  }
}
.window .graph-tab .right-add-box .right-add {
  width: 120px;
  height: 600px;
  color: #6bfd00;
  margin-top: 135px;
}
.window .graph-tab .top-add-box {
  padding-left: 120px;
}
.window .graph-tab .top-add-box .top-add {
  width: 970px;
  height: 90px;
  color: #6bfd00;
  margin: 0 auto;
}
.window .graph-tab .last-text-show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.window .graph-tab .last-text-show span {
  color: red;
}
.window .graph-tab .last-text-show h1 {
  font-size: 20px !important;
  font-style: normal !important;
  text-shadow: none !important;
  word-spacing: 3px;
  color: rgba(0, 0, 0, 0.6980392157) !important;
}
@media only screen and (max-width: 767px) {
  .window {
    width: 100%;
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0px !important;
    padding: 0px !important;
    height: calc(100% - 84px) !important;
    max-height: 100% !important;
    box-shadow: none !important;
    position: fixed !important;
    top: 42px !important;
    left: 0px !important;
  }
  .window.react-draggable {
    transform: translate(0) !important;
  }
  .window .tab-button {
    width: auto;
    min-width: 97px;
    white-space: nowrap;
    /* width: 50px; */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px !important;
    padding: 0px 9px !important;
  }
}
@media only screen and (max-width: 767px) and (max-width: 430px) {
  .window .tab-button {
    white-space: nowrap;
    /* width: 50px; */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px !important;
  }
}

.link-text {
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .hidMob {
    display: none;
  }
}

.scroll-container {
  overflow: hidden;
  width: 100%;
}

.scrolling-text {
  animation: scroll-left 10s linear infinite;
  white-space: nowrap;
}

@keyframes scroll-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.ShoMob {
  display: none;
}
@media only screen and (max-width: 769px) {
  .ShoMob {
    display: block;
    width: 100%;
    text-align: end;
  }
  .ShoMob span {
    color: red;
  }
  .ShoMob h1 {
    font-size: 20px;
    font-style: normal !important;
    text-shadow: none !important;
    word-spacing: 3px;
    color: rgba(0, 0, 0, 0.6980392157) !important;
  }
}
@media only screen and (max-width: 769px) and (max-width: 376px) {
  .ShoMob h1 {
    font-size: 16px !important;
  }
}

.close-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  transform: rotateZ(45deg);
  position: relative;
}
.close-icon:before, .close-icon:after {
  content: "";
  position: absolute;
  background: rgb(10, 10, 10);
}
.close-icon:before {
  height: 100%;
  width: 3px;
  left: 50%;
  transform: translateX(-50%);
}
.close-icon:after {
  height: 3px;
  width: 100%;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.image-button img {
  height: 20px;
  margin-right: 5px;
}

.table-data tbody {
  background-color: #12161c;
}
.table-data a {
  text-decoration: none;
  cursor: pointer;
  color: #12161c !important;
}
.table-data tr {
  color: #fff;
}
.table-data tr a {
  color: inherit !important;
}
.table-data tr a:hover {
  text-decoration: underline;
}
.table-data tr:hover {
  background-color: inherit !important;
}
.table-data span {
  color: #f0b90b;
}

#appBar {
  bottom: -3px;
  top: auto;
  background: #dbdb6e !important;
  box-shadow: inset 1px 1px 0px 1px #f5de95, inset -1px -1px 0 1px #a27f0e !important;
}
#appBar .right-time {
  padding: 7px 10px;
  background: inherit;
  width: 100px;
  text-align: center;
  margin-left: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#appBar button {
  width: 10rem;
  justify-content: flex-start;
}
#appBar button.start {
  width: 6rem;
}
#appBar button:not(.active) {
  background: #dbdb6e !important;
}
@media only screen and (max-width: 767px) {
  #appBar button {
    width: auto !important;
  }
  #appBar button span {
    display: none;
  }
}

.start-menu {
  width: 200px !important;
  position: absolute !important;
  left: 0px;
  bottom: 45px;
  z-index: 9999;
  border-width: 0 !important;
  border-left: 20px solid #f0b90b !important;
  background: #dbdb6e !important;
  box-shadow: inset 1px 1px 0px 1px #f5de95, inset -1px -1px 0 1px #a27f0e !important;
}
.start-menu li:hover {
  background: #12161c !important;
}
.start-menu li img {
  display: inherit;
  width: 30px;
}

#desktop-background {
  position: fixed !important;
  background: teal;
  z-index: -100 !important;
  inset: 0;
  height: 100%;
  width: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-sizing: border-box !important;
}

.home-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
  align-items: flex-end;
  height: calc(100% - 84px) !important;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  font-size: 1.5rem;
  padding: 20px;
  border-radius: 20px;
}
.home-text.justify-center {
  justify-content: center;
}
.home-text.justify-flex-start {
  justify-content: flex-start;
}
.home-text.align-flex-start {
  align-items: flex-start;
}
.home-text p {
  border-radius: 10px;
  color: #f0b90b;
}

strong {
  font-weight: 600 !important;
}

.width.w-50 {
  width: 50%;
}
.width.w-45 {
  width: 44.5%;
}
.width.w-33 {
  width: 33.33%;
}
@media only screen and (max-width: 767px) {
  .width {
    width: 100% !important;
  }
}

.logo-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: #12161c;
  border-radius: 50%;
  box-sizing: border-box;
}
.logo-box.justify-center {
  justify-content: center;
}
.logo-box.justify-flex-start {
  justify-content: flex-start;
}
.logo-box.align-flex-start {
  align-items: flex-start;
}

h1 {
  text-shadow: white 2px 2px;
  color: rgb(132, 133, 132);
  font-size: 25px;
  font-weight: bold !important;
  font-style: italic !important;
  color: #5f5f12;
}

h2 {
  font-size: 20px !important;
  line-height: 20px !important;
}

.desktop-icon {
  z-index: 1;
  position: absolute;
  width: 120px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  color: #f0b90b;
  left: 10px;
}
.desktop-icon.fanny-button {
  top: 50px;
}
.desktop-icon img {
  display: block;
  margin: 0 auto 5px;
  width: 40px;
}

.desktop-link {
  position: absolute;
  left: 10px;
  top: 50px;
}
.desktop-link.second {
  left: 130px;
  top: 50px;
}
.desktop-link .desktop-icon {
  position: static;
  margin-bottom: 20px;
}

.number-change {
  text-shadow: white 1px 1px;
  color: rgb(132, 133, 132);
  font-size: 14px;
  font-family: sans-serif !important;
  overflow: hidden !important;
  width: 60px !important;
}
.number-change span {
  animation: 3s steps(30) 0s infinite normal none running randomGen;
  display: block;
}

@keyframes randomGen {
  100% {
    transform: translateX(-3000%);
  }
}
@media only screen and (max-device-width: 767px) {
  .welcomeTabButton {
    margin-top: 0px;
    position: absolute !important;
    top: 50% !important;
    left: 19% !important;
  }
  .viewTableHeightBox {
    height: calc(100% - 123px) !important;
  }
  .window.info.infotext .window-content {
    max-height: 680px !important;
  }
  .desktop-icon img {
    width: 25px !important;
  }
  .desktop-icon {
    position: static;
    margin-bottom: 5px !important;
  }
  h1 {
    font-size: 20px !important;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none !important;
    -webkit-overflow-scrolling: auto !important;
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 10px !important;
    border: 2px solid #fff !important;
  }
  ::-webkit-scrollbar-track {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
    border-radius: 10px !important;
    background-color: #fff !important;
  }
  html {
    zoom: 0.9;
    font-size: 14px !important;
  }
  .height-auto {
    height: auto !important;
  }
  .hide {
    display: none !important;
  }
}
.coming {
  font-size: 30px;
  text-align: center;
}

.fKHPaC:before {
  width: 100% !important;
  height: 100% !important;
}

.displayCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.displayStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.displayEnd {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.displaySpacebetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.displaySpacearound {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.collectionBox {
  overflow: hidden;
  border-radius: 10px;
  background-color: rgba(245, 245, 245, 0.28);
  border-radius: 9px;
  width: calc(24% - 4px);
  border-style: solid;
  border-width: 2px;
  border-left-color: #fefefe;
  border-top-color: #fefefe;
  border-right-color: #0a0a0a;
  border-bottom-color: #0a0a0a;
  box-shadow: inset 1px 1px 0px 1px #dfdfdf, inset -1px -1px 0 1px #848584;
  position: relative;
}
@media (max-width: 1024px) {
  .collectionBox {
    width: calc(33% - 12px);
  }
}
@media (max-width: 767px) {
  .collectionBox {
    width: calc(50% - 12px);
  }
}
@media (max-width: 567px) {
  .collectionBox {
    width: 100%;
  }
}

.contentCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contentgrid {
  display: flex;
  grid-template-columns: auto auto auto auto;
  grid-gap: 16px;
  gap: 16px;
  padding: 10px;
  flex-wrap: wrap;
  grid: 400px/auto auto auto auto auto;
}

.collectionImg {
  height: 260px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1280) {
  .collectionImg {
    -o-object-fit: initial;
       object-fit: initial;
  }
}
@media (max-width: 767px) {
  .collectionImg {
    height: 307px !important;
    width: 100%;
  }
}

.featureImg1 {
  height: 26px;
  width: 26px;
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
}

.featureImg {
  height: 307px;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 1280) {
  .featureImg {
    -o-object-fit: contain;
       object-fit: contain;
  }
}
@media (max-width: 767px) {
  .featureImg {
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.contentBox {
  padding: 10px;
}

.dFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}

.window .tab-body .tab-content1 {
  overflow-y: auto !important;
  border: none !important;
  box-shadow: none !important;
  padding: 10px !important;
  box-sizing: border-box !important;
  height: 100% !important;
}

.desktop-icon1 {
  z-index: 1;
  /* position: absolute; */
  text-align: center;
  cursor: pointer;
  padding: 5px;
  margin: 0 20px;
  color: #0a0a0a;
  left: 10px;
}

.window.minesweeper1 {
  top: 20vh;
  min-width: 520px;
  max-width: 520px;
  width: 520px;
  height: 190px;
  left: 20%;
}

.balckBox {
  background: #000 !important;
  padding: 2px !important;
  margin-right: 3px !important;
  position: relative;
}

.whitepaper {
  opacity: 0.1;
  display: inline-block;
  padding-bottom: 4px !important;
}

.textfiledborder {
  box-shadow: rgb(223, 223, 223) 1px 1px 0px 1px inset, rgb(132, 133, 132) -1px -1px 0px 1px inset;
  border: solid;
  border-color: rgb(254, 254, 254) rgb(10, 10, 10) rgb(10, 10, 10) rgb(254, 254, 254);
}/*# sourceMappingURL=main.css.map */